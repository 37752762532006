import { policyList } from "@/enums/permissions";
import { InventoryBatch } from "@/interfaces/batch";
import { Product } from "@/interfaces/product";
import { store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { batchesAreOfSameType } from "@/utils/batch-actions.utils";
import { ActionSubheader, TableAction } from "helix-vue-components";
import { productService } from "./product.service";

class ProductActionService {
  public getProductsRowActions(removeCallback: () => void): TableAction[] {
    return [
      {
        icon: "far fa-check-circle",
        class: "icon-success",
        tooltip: String(i18n.t("inventory.published")),
        visibleCondition: arg => {
          return arg.item.status.published;
        }
      },
      {
        icon: "far fa-usd-circle",
        class: "icon-warning",
        tooltip: String(i18n.t("inventory.need_price")),
        visibleCondition: arg => {
          return arg.item.status.missing_prices;
        }
      },
      {
        icon: "far fa-exclamation-triangle",
        class: "icon-error",
        tooltip: String(i18n.t("inventory.missing_details")),
        visibleCondition: arg => {
          return arg.item.status.missing_details;
        }
      },
      {
        icon: "fal fa-pen",
        id: "btn_edit_prod",
        action: (product: Product) => {
          store.dispatch("RouterModule/go", {
            name: "products-edit",
            params: {
              id: product.sku,
              currentModel: product
            }
          });
        }
      },
      {
        icon: "",
        switch: "status.is_active",
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.enableDisableProducts
          ),
        modalActions: {
          modalNumber: 1,
          modalQuestion: String(i18n.t("inventory.toggle_product_conf")),
          modalSuccessText: "yes",
          modalSuccessAction: ({
            item,
            position,
            unselectModal,
            modelValue
          }) => {
            productService
              .toggleActiveProduct(item, modelValue)
              .then(() => unselectModal())
              .finally(() => removeCallback());
          },
          modalCancelText: "No"
        }
      }
    ];
  }

  public getSharedProductsRowActions(
    removeCallback: () => void
  ): TableAction[] {
    return [
      {
        icon: "far fa-check-circle",
        class: "icon-success",
        tooltip: String(i18n.t("inventory.published")),
        visibleCondition: arg => {
          return arg.item.status.published;
        }
      },
      {
        icon: "far fa-usd-circle",
        class: "icon-warning",
        tooltip: String(i18n.t("inventory.need_price")),
        visibleCondition: arg => {
          return arg.item.status.missing_prices;
        }
      },
      {
        icon: "far fa-exclamation-triangle",
        class: "icon-error",
        tooltip: String(i18n.t("inventory.missing_details")),
        visibleCondition: arg => {
          return arg.item.status.missing_details;
        }
      },
      {
        icon: "fal fa-pen",
        id: "btn_edit_prod",
        action: (product: Product) => {
          store.dispatch("RouterModule/go", {
            name: "shared-products-edit",
            params: {
              id: product.sku,
              currentModel: product
            }
          });
        }
      },
      {
        icon: "",
        switch: "status.is_active",
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.enableDisableProducts
          ),
        modalActions: {
          modalNumber: 1,
          modalQuestion: String(i18n.t("inventory.toggle_product_conf")),
          modalSuccessText: "yes",
          modalSuccessAction: ({
            item,
            position,
            unselectModal,
            modelValue
          }) => {
            productService
              .toggleActiveProduct(item, modelValue)
              .then(() => unselectModal())
              .finally(() => removeCallback());
          },
          modalCancelText: "No"
        }
      }
    ];
  }
  // sync button visibility condition on current inventory Page
  public getProductViewGeneralActions(
    v: string,
    inventoryReconciliationCallback: () => void
  ): ActionSubheader[] {
    return [
      {
        icon: "far fa-sync-alt",
        action: () => {
          inventoryReconciliationCallback();
        },
        visibleCondition: () => {
          return (
            store.getters["AuthModule/bioTrackTraceEnabled"] &&
            store.getters["PermissionsModule/hasPermission"](
              policyList.inventorySyncTool
            )
          );
        },
        tooltip: {
          text: i18n.t("biotrack_traceability.inventory_sync_check").toString(),
          displayCondition: () => true
        }
      },
      {
        icon: "fal fa-search",
        openOnMount: !!v,
        otherComponent: {
          name: "TableSearchComponent",
          activeSearch: v
        }
      }
    ];
  }

  public getProductGeneralActions(
    csvCallback: () => void,
    filtersCallback: () => void
  ): ActionSubheader[] {
    return [
      {
        icon: "fal fa-file-csv",
        action: csvCallback
      },
      {
        icon: "fal fa-plus",
        id: "btn_create_prod",
        action: (...arg: any[]) => {
          store.dispatch("RouterModule/go", {
            name: "products-add"
          });
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.createProducts
          )
      },
      {
        icon: "fal fa-search",
        id: "btn_search_prod",
        otherComponent: {
          name: "TableSearchComponent"
        }
      },
      {
        icon: "fal fa-filter",
        id: "btn_filter_prod",
        action: filtersCallback
      }
    ];
  }

  public getBatchViewRowActions(): TableAction[] {
    return [
      {
        icon: "fal fa-file-alt",
        label: "product",
        action: (batch: InventoryBatch) => {
          store.dispatch("RouterModule/go", {
            name: "batch-details",
            params: {
              id: batch.batchUid
            }
          });
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.viewBatchDetails
          )
      }
    ];
  }

  public getInventoryViewRowActions(): TableAction[] {
    return [
      {
        icon: "fal fa-box",
        label: "product",
        action: (product: Product) => {
          store.dispatch("RouterModule/go", {
            name: "products-edit",
            params: {
              id: product.sku,
              currentModel: product
            }
          });
        }
      }
    ];
  }

  public getInventoryViewMultiActions(callbacks: {
    [key: string]: () => any;
  }): TableAction[] {
    return [
      {
        icon: "fal fa-abacus",
        label: i18n.t("adjust").toString(),
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.adjustBatch
          ),
        action: () => {
          callbacks.adjust();
        }
      },
      {
        icon: "fal fa-arrows-h",
        label: i18n.t("convert").toString(),
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.convertBatch
          ),
        action: () => {
          callbacks.convert();
        }
      },
      {
        icon: "fal fa-arrows",
        label: i18n.t("move_batches").toString(),
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.moveBatch
          ),
        action: () => {
          callbacks.move();
        }
      },
      {
        icon: "call_split",
        label: i18n.t("split").toString(),
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.splitBatch
          ),
        action: () => {
          callbacks.split();
        }
      },
      {
        icon: "fal fa-compress-alt",
        label: i18n.t("combine").toString(),
        class: "buttonClass_2",
        visibleCondition: (arg: InventoryBatch[]) => {
          return arg.length > 1 && batchesAreOfSameType(arg);
        },
        action: () => {
          callbacks.combine();
        }
      },
      {
        icon: "far fa-long-arrow-alt-up",
        label: i18n.t("outbound").toString(),
        class: "iconButtonClassRotate45",
        visibleCondition: (arg: InventoryBatch[]) => {
          return !arg.find(b => !!b.quantities.RESERVED);
        },
        action: () => {
          callbacks.outbound();
        }
      },
      {
        icon: "fal fa-clipboard-list-check",
        label: i18n.t("quote").toString(),
        visibleCondition: (arg: InventoryBatch[]) => {
          return !arg.find(b => !!b.quantities.RESERVED);
        },
        action: () => {
          callbacks.quote();
        }
      },
      {
        icon: "fal fa-barcode-alt",
        label: i18n.t("print_barcodes").toString(),
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.printBatchBarcodes
          ),
        action: () => {
          callbacks.print();
        }
      },
      {
        icon: "fal fa-print",
        label: i18n.t("print_labels").toString(),
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.printBatchLabels
          ),
        action: () => {
          callbacks.label();
        }
      }
    ];
  }

  public getMultiAction(onSuccess: () => void): TableAction[] {
    return [
      {
        icon: "fal fa-eye",
        action(products: Product[]) {
          productService
            .enableMultiple(products.map(product => product.sku))
            .then(() => onSuccess());
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.enableDisableProducts
          )
      },
      {
        icon: "fal fa-eye-slash",
        action(products: Product[]) {
          productService
            .disableMultiple(products.map(product => product.sku))
            .then(() => onSuccess());
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.enableDisableProducts
          )
      }
    ];
  }
}

export const productActionService = new ProductActionService();
