import debounce from "lodash/debounce";
import { Component, Vue } from "vue-property-decorator";
import Template from "./ProductFilters.template.vue";
@Component({
  mixins: [Template]
})
export class ProductFiltersComponent extends Vue {
  public loaded: boolean = true;
  public isActive = true;
  public isPublished = false;
  public isMissingDetails = false;
  public isMissingPrice = false;
  protected dFilter = debounce(
    c =>
      c.$emit("filter", {
        active: c.isActive,
        published: c.isPublished,
        details: c.isMissingDetails,
        price: c.isMissingPrice
      }),
    500
  );

  public toggleActive() {
    this.isActive = !this.isActive;
    this.filter();
  }

  public togglePublished() {
    this.isPublished = !this.isPublished;
    this.filter();
  }

  public toggleMissingDetails() {
    this.isMissingDetails = !this.isMissingDetails;
    this.filter();
  }

  public toggleMissingPrice() {
    this.isMissingPrice = !this.isMissingPrice;
    this.filter();
  }

  public filter() {
    this.dFilter(this);
  }
}
